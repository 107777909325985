<template>
  <router-link
    tag="a"
    class="columns"
    :to="{ name: 'getQuote' }">

    <div class="container is-hidden-touch">
      <div class="columns is-gapless">
        <div class="column">
          <div class="content">
            <div class="boxed">
              <h2>
                <strong>
                    We've Got You Covered With<br>
                    Our 5 Star Rated Travel Insurance
                </strong>
              </h2>
              <ul>
                <router-link
                  :to="{ name: 'covid19Cover' }"
                  tag="li"
                  class="bullet">
                  Defaqto 5 Star Rated* Travel Insurance
                </router-link>
                <li class="bullet">
                  "Excellent" Rating on Trustpilot
                </li>
                <li class="bullet">
                  ‘Best Travel Insurance Provider’ – Insurance Choice Awards 2022
                </li>
              </ul>
              <div class="btn-zone">
                <router-link
                  :to="{ name: 'getQuote'}"
                  tag="button"
                  class="hvr-shadow-radial">
                  GET A QUOTE
                </router-link>
                <div class="small-text">*Platinum and Gold Policies only</div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  background: url('/img/banner-rounded-edges.png') no-repeat top right;
  background-size: 100% 100%;
  border-radius: 0 5px 5px 0;
}

.content {
  color: white;
  padding: 30px;
  margin: 30px 0 0 40px;
  border-radius: 5px 0 0 5px;
  strong {
    color: white;
  }
  h1 {
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    font-weight: 200;
    margin-left: 80px;
    margin-bottom: 5px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 15px 10px;
    font-weight: 200;
    .bullet {
      font-size: 20px;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      background: url('/img/tick-icon.png') no-repeat 0px 0px;
      height: 50px;
      padding-left: 40px;
      padding-top: 6px;
      margin-bottom: 0px;
      font-weight: 500;
    }

    li {
      margin-top: 0px;
      margin-bottom: 0px;
    }    
  }
  .boxed {
    // background-color: $c2g_blue;
    // width: 50%;
    // padding: 10px;
  }

  button {
    background-color: $c2g_orange;
    border: 0;
    width: 250px;
    height: 50px;
    color: white;
    font-size: 22px;
    cursor: pointer;
    border-radius: 5px;
  }
  p {
    font-size: 20px;
    color: white;
    margin-bottom: 5px;
    font-weight: 200;
    margin-left: 50px;
  }
  .small-text {
    font-size: 12px;
    margin: 0 0 20px 30px;
  }
  .bottom-link {
    margin-top: 10px;
    margin-left: 100px;
    text-decoration: underline;
    margin-left: 170px;
    cursor: pointer;
  }
  .hvr-shadow-radial {
    margin-bottom: 10px;
  }
  .btn-zone {
    padding-left: 50px;
  }
}
</style>
